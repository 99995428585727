import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import useForm from '../../hooks/useForm';
import useFetch from '../../hooks/useFetch';
import { Global } from '../../helpers/Global';

const ActionModal = ({ isOpen, toggle, actionType, apiEndpoint, selectedRow, onSuccess }) => {
    const initialFormState = {
        monto: '',
        id_metodo: '',
        id_comprobante: '',
        nro_comprobante: '',
        id: '' // Agregar el ID aquí
    };
    
    const { form, changed, resetForm, setForm } = useForm(initialFormState);
    const { data: metodos, fetchAllData: fetchAllMetodos } = useFetch('metodo');
    const { data: comprobantes, fetchAllData: fetchAllComprobantes } = useFetch('comprobante');
    
    useEffect(() => {
        if (isOpen) {
            fetchAllMetodos();
            fetchAllComprobantes();
            // Setear el ID del registro seleccionado al abrir el modal
            setForm((prevForm) => ({
                ...prevForm,
                id: selectedRow?.id || '' // Agregar el ID del registro al formulario
            }));
        }
    }, [isOpen, selectedRow, setForm]);  

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validar que los campos requeridos estén llenos
        if (!form.monto || !form.id_metodo || !form.id_comprobante || !form.nro_comprobante || !form.id) {
            Swal.fire('Error', 'Por favor complete todos los campos.', 'error');
            return;
        }
    
        try {
            const response = await fetch(`${Global.url}${apiEndpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token'),
                },
                body: JSON.stringify(form),
            });
    
            if (response.ok) {
                const data = await response.json();
                Swal.fire('Éxito', `${actionType} realizado correctamente.`, 'success');
    
                if (typeof onSuccess === 'function') {
                    // Determina si el objeto es deudores o acreedores
                    const entity = data.deudores || data.acreedores;
    
                    // Verifica si el objeto existe y formatea la fecha
                    if (entity && entity.fecha) {
                        entity.fecha = new Date(entity.fecha).toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                    }
    
                    onSuccess(entity); // Pasa el objeto actualizado con la fecha formateada
                }
    
                resetForm();
                toggle();
            } else {
                const errorData = await response.json();
                Swal.fire('Error', errorData.message || 'Ocurrió un error al realizar la operación.', 'error');
            }
        } catch (error) {
            console.error('Error al realizar la operación:', error);
            Swal.fire('Error', 'Ocurrió un error al realizar la operación.', 'error');
        }
    };
    

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            <ModalHeader toggle={toggle}>
                {actionType === 'Cobrar' ? 'Cobrar Deudor' : 'Pagar Acreedor'}
            </ModalHeader>
            <ModalBody>
                <form id="actionForm" onSubmit={handleSubmit}>
                    <div className="row">
                        {/* Monto */}
                        <div className="form-group col-md-6">
                            <label htmlFor="monto">Monto</label>
                            <input
                                type="number"
                                className="form-control"
                                id="monto"
                                name="monto"
                                placeholder="Ingrese el monto"
                                value={form.monto}
                                onChange={changed}
                            />
                        </div>

                        {/* Método de Pago */}
                        <div className="form-group col-md-6">
                            <label htmlFor="id_metodo">Método de Pago</label>
                            <select
                                className="form-control"
                                id="id_metodo"
                                name="id_metodo"
                                value={form.id_metodo}
                                onChange={changed}
                            >
                                <option value="">Seleccione un método</option>
                                {metodos.map((metodo) => (
                                    <option key={metodo.id} value={metodo.id}>
                                        {metodo.descripcion}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        {/* Comprobante */}
                        <div className="form-group col-md-6">
                            <label htmlFor="id_comprobante">Comprobante</label>
                            <select
                                className="form-control"
                                id="id_comprobante"
                                name="id_comprobante"
                                value={form.id_comprobante}
                                onChange={changed}
                            >
                                <option value="">Seleccione un comprobante</option>
                                {comprobantes.map((comprobante) => (
                                    <option key={comprobante.id} value={comprobante.id}>
                                        {comprobante.descripcion}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Número de Comprobante */}
                        <div className="form-group col-md-6">
                            <label htmlFor="nro_comprobante">Nº de Comprobante</label>
                            <input
                                type="number"
                                className="form-control"
                                id="nro_comprobante"
                                name="nro_comprobante"
                                placeholder="Ingrese el número"
                                value={form.nro_comprobante}
                                onChange={changed}
                            />
                        </div>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancelar
                </Button>
                <Button color="primary" type="submit" form="actionForm">
                    Confirmar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ActionModal;

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Global } from './Global';

// Función para convertir elementos React a texto plano
export const extractTextFromReactElement = (element) => {
    if (typeof element === 'string') return element;
    if (typeof element === 'number') return element.toString();
    if (Array.isArray(element)) return element.map(extractTextFromReactElement).join(' ');
    if (element.props && element.props.children) {
        return extractTextFromReactElement(element.props.children);
    }
    return '';
};

// Función para obtener todos los datos para exportar
export const fetchAllData = async (entity) => {
    const request = await fetch(`${Global.url}${entity}/listarTodo`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }
    });

    const data = await request.json();
    return data[`${entity}s`];
};

// Función para exportar datos a PDF
export const exportPDF = async (entity, columns, exportTitle) => {
    const allData = await fetchAllData(entity); // Obtener todos los datos
    const doc = new jsPDF();
    const tableColumn = columns.map(col => col.header);
    const tableRows = [];

    allData.forEach(row => {
        const rowData = columns.map(col => {
            if (col.render) {
                const renderedValue = col.render(row);
                return extractTextFromReactElement(renderedValue);
            }
            return row[col.accessor];
        });
        tableRows.push(rowData);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
    });
    doc.text(exportTitle, 14, 15);
    doc.save(`${exportTitle}.pdf`);
};

// Función para exportar datos a Excel
export const exportExcel = async (entity, columns, exportTitle) => {
    const allData = await fetchAllData(entity); // Obtener todos los datos
    const ws = XLSX.utils.json_to_sheet(allData.map(row => {
        const rowData = {};
        columns.forEach(col => {
            if (col.render) {
                const renderedValue = col.render(row);
                rowData[col.header] = extractTextFromReactElement(renderedValue);
            } else {
                rowData[col.header] = row[col.accessor];
            }
        });
        return rowData;
    }));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, exportTitle);
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${exportTitle}.xlsx`);
};
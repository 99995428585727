import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useForm from '../../hooks/useForm';

const CrudComprobante = ({ isOpen, toggle, onSubmit, comprobanteId, onUpdateComprobante }) => {
    const { form, changed, resetForm, setForm } = useForm({
        descripcion: ''
    });

    useEffect(() => {
        const getComprobante = async () => {
            if (comprobanteId) {
                const request = await fetch(`${Global.url}comprobante/obtener/${comprobanteId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    }
                });
                const data = await request.json();
                const { comprobante } = data;
                setForm({
                    descripcion: comprobante.descripcion
                });
            } else {
                resetForm();
            }
        };

        if (isOpen) {
            getComprobante();
        }
    }, [comprobanteId, isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedComprobante = { ...form };
        const url = comprobanteId ? `${Global.url}comprobante/actualizar/${comprobanteId}` : `${Global.url}comprobante/registrar`;
        const method = comprobanteId ? 'PUT' : 'POST';

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(updatedComprobante)
            });

            const data = await response.json();

            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: `Comprobante ${comprobanteId ? 'actualizado' : 'creado'} correctamente`,
                });

                onUpdateComprobante(); // Llama a la función para actualizar el comprobante en Comprobante.js
                resetForm();
                onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
                toggle(); // Cierra el modal
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message,
                });
                console.error(`Error ${comprobanteId ? 'updating' : 'creating'} comprobante:`, data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Ocurrió un error al ${comprobanteId ? 'actualizar' : 'crear'} la comprobante`,
            });
            console.error(`Error ${comprobanteId ? 'updating' : 'creating'} comprobante:`, error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{comprobanteId ? 'Editar' : 'Agregar'} Comprobante</ModalHeader>
            <ModalBody>
                <form id="editUserForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="descripcion">Descripción</label>
                        <input
                            type="text"
                            className="form-control"
                            id="descripcion"
                            name="descripcion"
                            placeholder="Ingrese la descripcion"
                            value={form.descripcion}
                            onChange={changed}
                        />
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cerrar</Button>
                <Button color="primary" type="submit" form="editUserForm">Guardar cambios</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CrudComprobante;

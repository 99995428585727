import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const TooltipComponent = ({ content, target }) => {
    const [position, setPosition] = useState({ top: 0, left: 0, visible: false });

    useEffect(() => {
        if (target) {
            const rect = target.getBoundingClientRect();
            setPosition({
                top: rect.bottom + window.scrollY + 5, // Ajusta la posición vertical
                left: rect.left + window.scrollX, // Ajusta la posición horizontal
                visible: true,
            });
        }
    }, [target]);

    if (!position.visible) return null;

    return createPortal(
        <div
            className="tooltip-container"
            style={{
                position: 'absolute',
                top: position.top,
                left: position.left,
            }}
        >
            {content}
        </div>,
        document.body
    );
};

export default TooltipComponent;

import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useForm from '../../hooks/useForm';

const CrudProducto = ({ isOpen, toggle, onSubmit, productoId, onUpdateProducto }) => {
    const { form, changed, resetForm, setForm } = useForm({
        product: '',
        descripcion: '',
        stock: '',
        marcaID: '',
        categoriaID: ''
    });

    const [marcas, setMarcas] = useState([]);
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        const getProducto = async () => {
            if (productoId) {
                const request = await fetch(`${Global.url}producto/obtener/${productoId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    }
                });
                const data = await request.json();

                const { productos } = data;
                setForm({
                    product: productos.producto,
                    descripcion: productos.descripcion,
                    stock: productos.stock,
                    marcaID: productos.id_marca,
                    categoriaID: productos.id_categoria
                });
                
            } else {
                setForm({
                    product: '',
                    descripcion: '',
                    stock: '',
                    marcaID: '',
                    categoriaID: ''
                });
            }
        };

        const getMarcas = async () => {
            const request = await fetch(`${Global.url}marca/listar`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            const data = await request.json();
            setMarcas(data.marcas);
        };

        const getCategorias = async () => {
            const request = await fetch(`${Global.url}categoria/listar`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            const data = await request.json();
            setCategorias(data.categorias);
        };

        if (isOpen) {
            getProducto();
            getMarcas();
            getCategorias();
        }
    }, [productoId, isOpen, setForm]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedProducto = { ...form };
        const url = productoId ? `${Global.url}producto/actualizar/${productoId}` : `${Global.url}producto/registrar`;
        const method = productoId ? 'PUT' : 'POST';
        
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(updatedProducto)
            });

            const data = await response.json();

            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: `Producto ${productoId ? 'actualizado' : 'creado'} correctamente`,
                });

                onUpdateProducto(); // Llama a la función para actualizar el productoe en Producto.js
                resetForm();
                onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
                toggle(); // Cierra el modal
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message,
                });
                console.error(`Error ${productoId ? 'updating' : 'creating'} producto:`, data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Ocurrió un error al ${productoId ? 'actualizar' : 'crear'} el producto`,
            });
            console.error(`Error ${productoId ? 'updating' : 'creating'} producto:`, error);
        }
    };
    
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{productoId ? 'Editar' : 'Agregar'} Producto</ModalHeader>
            <ModalBody>
                <form id="editProductoForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="product">Producto</label>
                        <input
                            type="text"
                            className="form-control"
                            id="product"
                            name="product"
                            placeholder="Ingrese el producto"
                            value={form.product}
                            onChange={changed}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="descripcion">Descripción</label>
                        <input
                            type="text"
                            className="form-control"
                            id="descripcion"
                            name="descripcion"
                            placeholder="Ingrese la descripcion"
                            value={form.descripcion}
                            onChange={changed}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor='stock'>Stock</label>
                        <input
                            type="number"
                            className="form-control"
                            id="stock"
                            name="stock"
                            placeholder='Ingrese el stock'
                            value={form.stock}
                            onChange={changed}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="marca">Marca</label>
                        <select
                            className="form-control"
                            id="marca"
                            name="marcaID"
                            value={form.marcaID}
                            onChange={changed}
                        >
                            <option value="">Seleccione una marca</option>
                            {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                    {marca.descripcion}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="categoria">Categoría</label>
                        <select
                            className="form-control"
                            id="categoria"
                            name="categoriaID"
                            value={form.categoriaID}
                            onChange={changed}
                        >
                            <option value="">Seleccione una categoría</option>
                            {categorias.map((categoria) => (
                                <option key={categoria.id} value={categoria.id}>
                                    {categoria.descripcion}
                                </option>
                            ))}
                        </select>
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cerrar</Button>
                <Button color="primary" type="submit" form="editProductoForm">Guardar cambios</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CrudProducto;

import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useForm from '../../hooks/useForm';

const CrudClient = ({ isOpen, toggle, onSubmit, clientId, onUpdateClient }) => {
  const { form, changed, resetForm, setForm } = useForm({
    nombre: '',
    direccion: '',
    ruc: '',
    telefono: '',
    fecha_registro: ''
  });

  useEffect(() => {
    const getClient = async () => {
      if (clientId) {
        const request = await fetch(`${Global.url}client/obtener/${clientId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
          }
        });
        const data = await request.json();
        const { client } = data;
        setForm({
          nombre: client.nombre,
          direccion: client.direccion,
          ruc: client.ruc,
          telefono: client.telefono,
          fecha_registro: client.fecha_registro.split('T')[0] // Transforma la fecha a YYYY-MM-DD
        });
      } else {
        const currentDate = new Date().toISOString().split('T')[0];
        setForm({
          nombre: '',
          direccion: '',
          ruc: '',
          telefono: '',
          fecha_registro: currentDate // Establece la fecha actual
        });
      }
    };

    if (isOpen) {
      getClient();
    }
  }, [clientId, isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedClient = { ...form };
    const url = clientId ? `${Global.url}client/actualizar/${clientId}` : `${Global.url}client/registrar`;
    const method = clientId ? 'PUT' : 'POST';

    try {

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(updatedClient)
      });

      const data = await response.json();
      
      if (data.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: `Usuario ${clientId ? 'actualizado' : 'creado'} correctamente`,
        });
        
        onUpdateClient(); // Llama a la función para actualizar el cliente en Client.js
        resetForm();
        onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
        toggle(); // Cierra el modal
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message,
        });
        console.error(`Error ${clientId ? 'updating' : 'creating'} client:`, data.message);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Ocurrió un error al ${clientId ? 'actualizar' : 'crear'} el cliente`,
      });
      console.error(`Error ${clientId ? 'updating' : 'creating'} cliente:`, error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{clientId ? 'Editar' : 'Agregar'} Cliente</ModalHeader>
      <ModalBody>
        <form id="editUserForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              placeholder="Ingrese el nombre"
              value={form.nombre}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label htmlFor="direccion">Dirección</label>
            <input
              type="text"
              className="form-control"
              id="direccion"
              name="direccion"
              placeholder="Ingrese la direccion"
              value={form.direccion}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label htmlFor='ruc'>RUC/CI</label>
            <input
              type="text"
              className="form-control"
              id="ruc"
              name="ruc"
              placeholder='Ingrese el RUC/CI'
              value={form.ruc}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label htmlFor='telefono'>Telefono</label>
            <input
              type="text"
              className="form-control"
              id="telefono"
              name="telefono"
              placeholder='Ingrese el telefono'
              value={form.telefono}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label htmlFor='fecha_registro'>Fecha de Registro</label>
            <input
              type="date"
              className="form-control"
              id="fecha_registro"
              name="fecha_registro"
              value={form.fecha_registro}
              onChange={changed}
            />
          </div>
          
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cerrar</Button>
        <Button color="primary" type="submit" form="editUserForm">Guardar cambios</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CrudClient;

// hooks/useFetch.js
import { useState, useEffect } from 'react';
import { Global } from '../helpers/Global';

const useFetch = (entity) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchData = async (page = 1, itemsPerPage = 10, id = undefined) => {
        const param = id ? id : page;
        try {
            setLoading(true);
            const response = await fetch(`${Global.url}${entity}/listar/${param}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            const result = await response.json();
            
            setData(result[`${entity}s`]); // HAY QUE ATENDER LO QUE DEVUELVE LA API PARA PODER SETEAR AL ESTADO
            setCurrentPage(result.currentPage);
            setTotalPages(result.totalPages);
            return {
                data: result[`${entity}s`],
                currentPage: result.currentPage,
                totalPages: result.totalPages
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchSearchData = async (searchTerm) => {
        try {
            setLoading(true);
            const response = await fetch(`${Global.url}${entity}/buscar/${searchTerm}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            const result = await response.json();
            setData(result[`${entity}s`]);
            setCurrentPage(1);
            setTotalPages(1); // Asumimos que la búsqueda devuelve todos los resultados en una página
            return {
                data: result[`${entity}s`],
                currentPage: 1,
                totalPages: 1
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${Global.url}${entity}/listartodo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            const result = await response.json();
            setData(result[`${entity}s`]);
            setCurrentPage(1);
            setTotalPages(1); // Asumimos que `listartodo` devuelve todos los resultados en una página
            return {
                data: result[`${entity}s`],
                currentPage: 1,
                totalPages: 1
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return { data, loading, error, fetchData, fetchSearchData, fetchAllData, currentPage, totalPages, setData };
};

export default useFetch;

import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudProducto from './CrudProducto';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

// Modal.setAppElement('#root');

const Producto = () => {
    const { data: producto, loading, error, fetchData, fetchSearchData, setData: setProductos } = useFetch('producto');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProductoId, setSelectedProductoId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (productoId = null) => {
        setSelectedProductoId(productoId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Id',
            accessor: 'id'
        },
        {
            header: 'Producto',
            accessor: 'producto'
        },
        {
            header: 'Descripcion',
            accessor: 'descripcion'
        },
        {
            header: 'Stock',
            accessor: 'stock',
            isNumeric: true 
        },
        {
            header: 'Marca',
            accessor: 'marca'
        },
        {
            header: 'Categoria',
            accessor: 'categoria'
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Productos</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Producto
                        </Button>
                    </div>

                    <GenericTable
                        entity="producto"
                        typeEntity="producto"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(producto) => toggleModal(producto.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Productos"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudProducto 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                productoId={selectedProductoId} 
                onUpdateProducto={handleReload}
            />
        </div>
    )
}

export default Producto;

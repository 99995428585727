import { useState } from 'react';

const useForm = (initialObj = {}) => {
    const [form, setForm] = useState(initialObj);
    const [files, setFiles] = useState({});

    const changed = ({ target }) => {
        const { name, value, type, files: targetFiles } = target;

        if (type === 'file') {
            setFiles({
                ...files,
                [name]: targetFiles[0]
            });
        } else {
            setForm({
                ...form,
                [name]: value
            });
        }
    };

    const resetForm = () => {
        setForm(initialObj);
        setFiles({});
    };
    
    return {
        form,
        files,
        changed,
        resetForm,
        setForm
    };
};

export default useForm;

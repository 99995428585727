import React, { useState, useEffect, createContext } from "react";
import { Global } from '../helpers/Global';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        authUser();
    }, []);

    const authUser = async () => {
        // Sacar datos del usuario identificado del localstorage
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        
        // Comprobar si tengo token y el user
        if(!token || !user){
            setLoading(false);
            return false;
        }

        // Transformar los datos a un objeto de js
        const userObj = JSON.parse(user);
        const userId = userObj.id;

        // Peticion ajax al backend que compruebe el token y que retorne datos del user
        const request = await fetch(Global.url + 'user/obtener/' + userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })

        const data = await request.json();
        
        // Setear el estado auth
        setAuth(data.user);
        
        setLoading(false);

    }

    return (
        <AuthContext.Provider value={{ auth, setAuth, loading}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
import React, { useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

    const {setAuth} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Vaciar el localstorage
        localStorage.clear();

        // Setear estados globales a vacio
        setAuth({});

        // Navigate (redireccion) al login
        navigate('/login', {replace: true});

    })

  return (
    <h1>Cerrando Sesión ...</h1>
  )
}

export default Logout

import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import useAuth from "../../../hooks/useAuth";

const PrivateLayout = () => {

  const { auth, loading } = useAuth();
  
  // Si no hay un usuario autenticado, redirigir al login
  if (loading) {
    return <h1>Cargando...</h1>
  }
  if (!auth.id) {
    return <Navigate to="/login" />
  }
  return (
    <main>
      {/********header**********/}
      <Header />
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        <aside className="sidebarArea shadow" id="sidebarArea">
          <Sidebar />
        </aside>
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="p-4" fluid>
            <Outlet />
          </Container>
        </div>
      </div>
    </main>
  );
};

export default PrivateLayout;

import React from 'react';
import { Modal, Button } from 'reactstrap';

const ImageModal = ({ isOpen, imageSrc, toggle }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} contentLabel="Imagen Grande">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <Button
                    color="secondary"
                    onClick={toggle}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 1000,
                    }}
                >
                    Cerrar
                </Button>
                <img src={imageSrc} alt="Imagen Grande" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
            </div>
        </Modal>
    );
};

export default ImageModal;

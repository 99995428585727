import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { Global } from '../../helpers/Global';
// import Modal from 'react-modal';
import userpng from "../../assets/images/users/user.png";
import CrudUser from './CrudUser';
import GenericTable from '../datatables/GenericTable';
import ImageModal from '../datatables/ImageModal';
import useFetch from '../../hooks/useFetch';

// Modal.setAppElement('#root');

const User = () => {
    const { data: users, loading, error, fetchData, fetchSearchData, setData: setUsers } = useFetch('user');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [reload, setReload] = useState(false);

    const toggleModal = (userId = null) => {
        setSelectedUserId(userId);
        setModalOpen(!modalOpen);
    };

    const toggleImageModal = (imageSrc = '') => {
        setSelectedImage(imageSrc);
        setImageModalOpen(!imageModalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Usuario',
            accessor: 'nombre',
            render: (row) => (
                <div className='d-flex align-items-center'>
                    {row.foto_perfil != 0 &&
                        <img
                            src={Global.url + 'user/avatar/' + row.foto_perfil}
                            className='rounded-circle'
                            alt='avatar'
                            width='45'
                            height='45'
                            onClick={() => toggleImageModal(Global.url + 'user/avatar/' + row.foto_perfil)}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                    {row.foto_perfil == 0 &&
                        <img
                            src={userpng}
                            className='rounded-circle'
                            alt='avatar'
                            width='45'
                            height='45'
                            onClick={() => toggleImageModal(userpng)}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                    <div className='ms-3'>
                        <h6 className='mb-0'>{row.nombre}</h6>
                    </div>
                </div>
            )
        },
        {
            header: 'Rol',
            accessor: 'nivel',
            render: (row) => (
                <span>{row.nivel == 1 ? 'Administrador' : 'Usuario'}</span>
            )
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Usuarios</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Usuario
                        </Button>
                    </div>

                    <GenericTable
                        entity="user"
                        typeEntity="usuario"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(user) => toggleModal(user.id)}
                        onDelete={() => {}}
                        exportTitle="Listado de Usuarios"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudUser 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                userId={selectedUserId} 
                onUpdateUser={handleReload} 
            />
            <ImageModal
                isOpen={imageModalOpen}
                imageSrc={selectedImage}
                toggle={toggleImageModal}
            />
        </div>
    )
}

export default User

import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudDeudor from './CrudDeudor';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

const Deudor = () => {
    const { data: deudor, loading, error, fetchData, fetchSearchData, setData: setDeudor } = useFetch('deudor');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDeudorId, setSelectedDeudorId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (deudorId = null) => {
        setSelectedDeudorId(deudorId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };
    
    const columns = [
        {
            header: 'Deudor',
            accessor: 'deudor'
        },
        {
            header: 'Concepto',
            accessor: 'concepto'
        },
        {
            header: 'Monto',
            accessor: 'monto',
            isNumeric: true 
        },
        {
            header: 'Saldo',
            accessor: 'saldo',
            isNumeric: true 
        },
        {
            header: 'Fecha',
            accessor: 'fecha'
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Deudores</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Deudor
                        </Button>
                    </div>

                    <GenericTable
                        entity="deudor"
                        typeEntity="deudor"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(deudor) => toggleModal(deudor.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Deudores"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudDeudor 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                deudorId={selectedDeudorId} 
                onUpdateDeudor={handleReload}
            />
        </div>
    )
}

export default Deudor;

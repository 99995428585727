import React, { useEffect, useState } from 'react'
import styles from '../../assets/scss/style_404.module.scss'
import { NavLink } from 'react-router-dom';

const Page404 = () => {

    const [height, setHeight] = useState(window.innerHeight + 'px');

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight + 'px');
        };

        window.addEventListener('resize', handleResize);

        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (

        <div className={styles.errorBody} style={{ height: height }}>
            <div className={styles.noise}></div>
            <div className={styles.overlay}></div>
            <div className={styles.terminal}>
                <h1>Error <span className={styles.errorcode}>404</span></h1>
                <p className={styles.output}>La página que estás buscando podría haber sido eliminada, haber cambiado de nombre o estar temporalmente no disponible.</p>
                <p className={styles.output}>Por favor intenta <NavLink to="/login" className={styles.listado}>regresar</NavLink>.</p>
                <p className={styles.output}>Buena suerte.</p>
            </div>
        </div>
    )
}

export default Page404

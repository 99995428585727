import React from 'react';
import Login from '../../user/Login';
import useAuth from '../../../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const PublicLayout = () => {

  // Aqui importaremos la logica para la autenticacion
  const { auth } = useAuth();

  return (
    <>
      {!auth.id
        ? <Login />
        : <Navigate to="/app/venta" />
      }
    </>
  )
}

export default PublicLayout

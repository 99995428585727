import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudIngreso from './CrudIngreso';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

const Ingreso = () => {
    const { data: ingreso, loading, error, fetchData, fetchSearchData, setData: setIngreso } = useFetch('ingreso');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedIngresoId, setSelectedIngresoId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (ingresoId = null) => {
        setSelectedIngresoId(ingresoId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Cliente',
            accessor: 'cliente'
        },
        {
            header: 'Concepto',
            accessor: 'concepto'
        },
        {
            header: 'Monto',
            accessor: 'monto',
            isNumeric: true 
        },
        {
            header: 'Fecha',
            accessor: 'fecha'
        },
        {
            header: 'Metodo de pago',
            accessor: 'metodo_pago'
        },
        {
            header: 'Comprobante',
            accessor: 'comprobante'
        },
        {
            header: 'Nº comprobante',
            accessor: 'nro_comprobante',
            isNumeric: true 
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Ingresos</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Ingreso
                        </Button>
                    </div>

                    <GenericTable
                        entity="ingreso"
                        typeEntity="ingreso"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(ingreso) => toggleModal(ingreso.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Ingresos"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudIngreso 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                ingresoId={selectedIngresoId} 
                onUpdateIngreso={handleReload}
            />
        </div>
    )
}

export default Ingreso;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import Logo from "./Logo";
import { ReactComponent as LogoWhite } from "../../../assets/images/logos/adminprowhite.svg";
import user from "../../../assets/images/users/user.png";
import { Global } from "../../../helpers/Global";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const navigate = useNavigate();

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleLogout = () => {
    navigate('/app/logout');
  };

  const { auth } = useAuth();

  return (
    <Navbar color="white" light expand="md" className="fix-header">
      <div className="d-flex align-items-center">
        <div className="margen-derecha">
          <Logo />
        </div>

        <Button
          color="primary"
          className=" d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
        <Collapse navbar isOpen={isOpen}>
          <Nav className="me-auto" navbar>
          </Nav>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle color="transparent">
              {auth.foto_perfil !== 0 &&
                <img
                  src={Global.url + 'user/avatar/' + auth.foto_perfil}
                  alt="perfil"
                  className="rounded-circle"
                  width="45"
                  height="45"
                />
              }
              {auth.foto_perfil == 0 &&
                <img
                  src={user}
                  alt="perfil"
                  className="rounded-circle"
                  width="45"
                />
              }
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={handleLogout}>Cerrar sesión</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Collapse>
      </div>


    </Navbar>
  );
};

export default Header;

import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudAcreedor from './CrudAcreedor';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

const Acreedor = () => {
    const { data: acreedor, loading, error, fetchData, fetchSearchData, setData: setAcreedor } = useFetch('acreedor');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAcreedorId, setSelectedAcreedorId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (acreedorId = null) => {
        setSelectedAcreedorId(acreedorId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };
    
    const columns = [
        {
            header: 'Proveedor',
            accessor: 'proveedor'
        },
        {
            header: 'Concepto',
            accessor: 'concepto'
        },
        {
            header: 'Monto',
            accessor: 'monto',
            isNumeric: true 
        },
        {
            header: 'Saldo',
            accessor: 'saldo',
            isNumeric: true 
        },
        {
            header: 'Fecha',
            accessor: 'fecha'
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Acreedores</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Acreedor
                        </Button>
                    </div>

                    <GenericTable
                        entity="acreedor"
                        typeEntity="acreedor"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(acreedor) => toggleModal(acreedor.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Acreedores"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudAcreedor 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                acreedorId={selectedAcreedorId} 
                onUpdateAcreedor={handleReload}
            />
        </div>
    )
}

export default Acreedor;

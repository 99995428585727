import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';
import { Link, useNavigate } from 'react-router-dom';
import CrudVenta from './CrudVenta';

const Venta = () => {
    const { data: venta, loading, error, fetchData, fetchSearchData, setData: setVenta } = useFetch('venta');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVentaId, setSelectedVentaId] = useState(null);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();

    const toggleModal = (ventaId = null) => {
        setSelectedVentaId(ventaId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const handleNewPurchase = (id) => {
        navigate(`/app/nueva_venta/${id}`);
    };

    const columns = [
        {
            header: 'Proveedor',
            accessor: 'proveedor'
        },
        {
            header: 'Total',
            accessor: 'total',
            isNumeric: true 
        },
        {
            header: 'Fecha Venta',
            accessor: 'fecha_venta'
        },
        {
            header: 'Pago',
            accessor: 'tipo_pago'
        },
        {
            header: 'Metodo',
            accessor: 'metodo'
        },
        {
            header: 'Comprobante',
            accessor: 'comprobante'
        },
        {
            header: 'Nº Comprobante',
            accessor: 'nro_comprobante',
            isNumeric: true 
        }
    ];

    const customActions = (rowData) => (
        <Button color="primary" className="me-2" onClick={() => handleNewPurchase(rowData.id)}>
            Cargar
        </Button>
    );

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Ventas</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Venta
                        </Button>
                    </div>

                    <GenericTable
                        entity="venta"
                        typeEntity="venta"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(venta) => toggleModal(venta.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Ventas"
                        reloadTrigger={reload}
                        customActions={customActions}
                    />
                </CardBody>
            </Card>
            <CrudVenta 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                ventaId={selectedVentaId} 
                onUpdateVenta={handleReload}
            />
        </div>
    )
}

export default Venta;

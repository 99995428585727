import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useForm from '../../hooks/useForm';
import useFetch from '../../hooks/useFetch';

const CrudAcreedor = ({ isOpen, toggle, onSubmit, acreedorId, onUpdateAcreedor }) => {
    const { form, changed, resetForm, setForm } = useForm({
        id_cliente: '',
        fecha: '',
        concepto: '',
        monto: '',
        saldo: ''
    });

    const { data: clientes, fetchAllData: fetchAllClientes } = useFetch('client');

    useEffect(() => {
        const getAcreedor = async () => {
            
            if (acreedorId) {
                const request = await fetch(`${Global.url}acreedor/obtener/${acreedorId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    }
                });
                const data = await request.json();
                const { acreedors } = data;
                setForm({
                    id_cliente: acreedors.id_cliente,
                    fecha: acreedors.fecha.split('T')[0],
                    concepto: acreedors.concepto,
                    monto: acreedors.monto,
                    saldo: acreedors.saldo
                });
            } else {
                const currentDate = new Date().toISOString().split('T')[0];
                setForm({
                    id_cliente: '',
                    fecha: currentDate,
                    concepto: '',
                    monto: '',
                    saldo: ''
                });
            }
        };

        if (isOpen) {
            getAcreedor();
            fetchAllClientes();
        }
    }, [acreedorId, isOpen, setForm]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedAcreedor = { ...form };
        const url = acreedorId ? `${Global.url}acreedor/actualizar/${acreedorId}` : `${Global.url}acreedor/registrar`;
        const method = acreedorId ? 'PUT' : 'POST';

        try {

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(updatedAcreedor)
            });

            const data = await response.json();

            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: `Acreedor ${acreedorId ? 'actualizada' : 'creada'} correctamente`,
                });

                onUpdateAcreedor(); // Llama a la función para actualizar el acreedor en Acreedor.js
                resetForm();
                onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
                toggle(); // Cierra el modal
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message,
                });
                console.error(`Error ${acreedorId ? 'updating' : 'creating'} acreedor:`, data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Ocurrió un error al ${acreedorId ? 'actualizar' : 'crear'} la acreedor`,
            });
            console.error(`Error ${acreedorId ? 'updating' : 'creating'} acreedor:`, error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{acreedorId ? 'Editar' : 'Agregar'} Acreedor</ModalHeader>
            <ModalBody>
                <form id="editAcreedorForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="id_cliente">Proveedor</label>
                        <select className="form-control" id="id_cliente" name="id_cliente" value={form.id_cliente} onChange={changed}>
                            <option value="">Seleccione un proveedor</option>
                            {clientes.map((cliente) => (
                                <option key={cliente.id} value={cliente.id}>
                                    {cliente.nombre}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor='concepto'>Concepto</label>
                        <input type="text" className="form-control" id="concepto" name="concepto" value={form.concepto} onChange={changed} placeholder='Ingrese el concepto'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor='monto'>Monto</label>
                        <input type="number" className="form-control" id="monto" name="monto" value={form.monto} onChange={changed} placeholder='Ingrese el monto'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor='saldo'>Saldo</label>
                        <input type="number" className="form-control" id="saldo" name="saldo" value={form.saldo} onChange={changed} placeholder='Ingrese el saldo'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor='fecha'>Fecha</label>
                        <input type="date" className="form-control" id="fecha" name="fecha" value={form.fecha} onChange={changed} />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cerrar</Button>
                <Button color="primary" type="submit" form="editAcreedorForm">Guardar cambios</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CrudAcreedor;

import Swal from 'sweetalert2';
import { Global } from '../helpers/Global';

export const generateFactura = async (rowData) => {
    try {
        // Validar y preparar los datos para el backend
        const datos = {
            cliente: rowData.cliente || 'Cliente no especificado',
            productos: rowData.productos && rowData.productos.length > 0 ? rowData.productos : ['Producto genérico'],
            total: rowData.total || '0.00'
        };

        // Llamada al backend para generar la factura
        const response = await fetch(`${Global.url}venta/factura`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(datos)
        });

        if (response.ok) {
            const blob = await response.blob();

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `factura.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            Swal.fire('Éxito', 'La factura se ha generado y descargado correctamente.', 'success');
        } else {
            throw new Error('No se pudo generar la factura');
        }
    } catch (error) {
        console.error('Error al generar la factura:', error);
        Swal.fire('Error', 'Ocurrió un error al generar la factura.', 'error');
    }
};

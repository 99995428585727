import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useForm from '../../hooks/useForm';

const CrudMoneda = ({ isOpen, toggle, onSubmit, monedaId, onUpdateMoneda }) => {
    const { form, changed, resetForm, setForm } = useForm({
        reales: '',
        dolares: '',
        fecha: ''
    });
    
    useEffect(() => {
        const getMoneda = async () => {
            if (monedaId) {
                const request = await fetch(`${Global.url}moneda/obtener/${monedaId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    }
                });
                const data = await request.json();
                const { moneda } = data;
                setForm({
                    reales: moneda.reales,
                    dolares: moneda.dolares,
                    fecha: moneda.fecha.split('T')[0]
                });
            } else {
                const currentDate = new Date().toISOString().split('T')[0];
                resetForm();
                setForm({
                    reales: '',
                    dolares: '',
                    fecha: currentDate
                });
            }
        };

        if (isOpen) {
            getMoneda();
        }
    }, [monedaId, isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedMoneda = { ...form };
        const url = monedaId ? `${Global.url}moneda/actualizar/${monedaId}` : `${Global.url}moneda/registrar`;
        const method = monedaId ? 'PUT' : 'POST';

        try {

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(updatedMoneda)
            });

            const data = await response.json();

            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: `Moneda ${monedaId ? 'actualizado' : 'creado'} correctamente`,
                });

                onUpdateMoneda(); // Llama a la función para actualizar el moneda en Moneda.js
                resetForm();
                onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
                toggle(); // Cierra el modal
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message,
                });
                console.error(`Error ${monedaId ? 'updating' : 'creating'} moneda:`, data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Ocurrió un error al ${monedaId ? 'actualizar' : 'crear'} la moneda`,
            });
            console.error(`Error ${monedaId ? 'updating' : 'creating'} moneda:`, error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{monedaId ? 'Editar' : 'Agregar'} Moneda</ModalHeader>
            <ModalBody>
                <form id="editUserForm" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="dolares">Dolares</label>
                        <input
                            type="text"
                            className="form-control"
                            id="dolares"
                            name="dolares"
                            placeholder="Ingrese los dolares"
                            value={form.dolares}
                            onChange={changed}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="reales">Reales</label>
                        <input
                            type="text"
                            className="form-control"
                            id="reales"
                            name="reales"
                            placeholder="Ingrese los reales"
                            value={form.reales}
                            onChange={changed}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor='fecha'>Fecha de Registro</label>
                        <input
                            type="date"
                            className="form-control"
                            id="fecha"
                            name="fecha"
                            value={form.fecha}
                            onChange={changed}
                        />
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cerrar</Button>
                <Button color="primary" type="submit" form="editUserForm">Guardar cambios</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CrudMoneda;

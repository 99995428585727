import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudMetodo from './CrudMetodo';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

const Metodo = () => {
    const { data: metodo, loading, error, fetchData, fetchSearchData, setData: setMetodo } = useFetch('metodo');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMetodoId, setSelectedMetodoId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (metodoId = null) => {
        setSelectedMetodoId(metodoId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Descripcion',
            accessor: 'descripcion'
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Metodos</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Metodo
                        </Button>
                    </div>

                    <GenericTable
                        entity="metodo"
                        typeEntity="metodo"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(metodo) => toggleModal(metodo.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Metodos"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudMetodo 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                metodoId={selectedMetodoId} 
                onUpdateMetodo={handleReload}
            />
        </div>
    )
}

export default Metodo;

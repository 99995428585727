import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudMoneda from './CrudMoneda';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

const Moneda = () => {
    const { data: moneda, loading, error, fetchData, fetchSearchData, setData: setMoneda } = useFetch('moneda');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMonedaId, setSelectedMonedaId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (monedaId = null) => {
        setSelectedMonedaId(monedaId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Dolares',
            accessor: 'dolares',
            isNumeric: true 
        },
        {
            header: 'Reales',
            accessor: 'reales',
            isNumeric: true 
        },
        {
            header: 'Fecha de Registro',
            accessor: 'fecha',
            render: (row) => {
                const date = new Date(row.fecha);
                return date.toLocaleDateString('es-ES', { timeZone: 'UTC' }); // Ajusta el formato según tus necesidades
            }
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Monedas</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Moneda
                        </Button>
                    </div>

                    <GenericTable
                        entity="moneda"
                        typeEntity="moneda"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(moneda) => toggleModal(moneda.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Monedas"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="iframe-container">
                        <iframe 
                            src="http://www.cambioschaco.com.py/widgets/cotizacion/?lang=es" 
                            allowFullScreen
                        ></iframe>
                    </div>
                </CardBody>
            </Card>
            <CrudMoneda 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                monedaId={selectedMonedaId} 
                onUpdateMoneda={handleReload}
            />
        </div>
    )
}

export default Moneda;

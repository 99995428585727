// src/components/Client.js
import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
// import Modal from 'react-modal';
import CrudClient from './CrudClient';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

// Modal.setAppElement('#root');

const Client = () => {
    const { data: clients, loading, error, fetchData, fetchSearchData, setData: setClients } = useFetch('client');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (clientId = null) => {
        setSelectedClientId(clientId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Nombre',
            accessor: 'nombre'
        },
        {
            header: 'RUC/CI',
            accessor: 'ruc'
        },
        {
            header: 'Telefono',
            accessor: 'telefono'
        },
        {
            header: 'Direccion',
            accessor: 'direccion'
        },
        {
            header: 'Fecha de Registro',
            accessor: 'fecha_registro'
        }        
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Clientes</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Cliente
                        </Button>
                    </div>

                    <GenericTable
                        entity="client"
                        typeEntity="cliente"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(client) => toggleModal(client.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Clientes"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudClient 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                clientId={selectedClientId} 
                onUpdateClient={handleReload}
            />
        </div>
    )
}

export default Client;

// import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
import PrivateLayout from "../components/layouts/private/PrivateLayout.js";
import PublicLayout from"../components/layouts/public/PublicLayout.js";

/***** Pages ****/
import Starter from "../components/views/Starter.js";
import About from "../components/views/About.js";
import Alerts from "../components/views/ui/Alerts";
import Badges from "../components/views/ui/Badges";
import Buttons from "../components/views/ui/Buttons";
import Cards from "../components/views/ui/Cards";
import Grid from "../components/views/ui/Grid";
import Tables from "../components/views/ui/Tables";
import Forms from "../components/views/ui/Forms";
import Breadcrumbs from "../components/views/ui/Breadcrumbs";
import Login from "../components/user/Login.js";
import Logout from "../components/user/Logout";
import Page404 from "../components/views/Page404";
import Users from "../components/user/User.js";
import Client from "../components/client/Client.js";
import Producto from "../components/producto/Producto.js";
import Marca from "../components/marca/Marca.js";
import Categoria from "../components/categoria/Categoria.js";
import Comprobante from "../components/comprobante/Comprobante.js";
import Metodo from "../components/metodo/Metodo.js";
import Moneda from "../components/moneda/Moneda.js";
import Compra from "../components/compra/Compra.js";
import NuevaCompra from "../components/compra/NuevaCompra.js";
import Acreedor from "../components/acreedor/Acreedor.js";
import Egreso from "../components/egreso/Egreso.js";
import Ingreso from "../components/ingreso/Ingreso.js";
import Deudor from "../components/deudor/Deudor.js";
import Venta from "../components/venta/Venta.js";
import NuevaVenta from "../components/venta/NuevaVenta.js";

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { path: "login", element: <Login /> },
    ]
  },
  {
    path: "/app",
    element: <PrivateLayout />,
    children: [
      { path: "", element: <Navigate to="/app/venta" /> },
      { path: "starter", element: <Starter /> },
      { path: "about", element: <About /> },
      { path: "alerts", element: <Alerts /> },
      { path: "badges", element: <Badges /> },
      { path: "buttons", element: <Buttons /> },
      { path: "cards", element: <Cards /> },
      { path: "grid", element: <Grid /> },
      { path: "table", element: <Tables /> },
      { path: "forms", element: <Forms /> },
      { path: "breadcrumbs", element: <Breadcrumbs /> },
      { path: "logout", element: <Logout /> },
      { path: "users", element: <Users /> },
      { path: "clients", element: <Client /> },
      { path: "producto", element: <Producto />},
      { path: "marca", element: <Marca />},
      { path: "categoria", element: <Categoria />},
      { path: "metodo", element: <Metodo />},
      { path: "comprobante", element: <Comprobante />},
      { path: "moneda", element: <Moneda />},
      { path: "compra", element: <Compra />},
      { path: "nueva_compra/:id", element: <NuevaCompra />},
      { path: "acreedor", element: <Acreedor />},
      { path: "egreso", element: <Egreso />},
      { path: "ingreso", element: <Ingreso />},
      { path: "deudor", element: <Deudor />},
      { path: "venta", element: <Venta />},
      { path: "nueva_venta/:id", element: <NuevaVenta />}

    ],
  },
  {
    path: "*",
    element: <Page404 />
  }
];

export default ThemeRoutes;

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import ButtonWithRef from '../layouts/private/ButtonRef';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import { Global } from '../../helpers/Global';
import Swal from 'sweetalert2';
import ReactCountryFlag from 'react-country-flag';

const NuevaCompra = () => {
    const { id } = useParams();
    const { data: detalleCompras, loading, error, fetchData, fetchSearchData, setData: setDetalleCompras } = useFetch('detalle_compra');
    const [reload, setReload] = useState(false);
    const { form, changed, resetForm, setForm } = useForm({
        productoID: '',
        cantidad: 0,
        precio_compra: 0,
        precio_venta: 0,
        descuento: 0
    });
    const [productos, setProductos] = useState([]);
    const [detallesCompras, setDetallesCompras] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);

    // Refs para los campos de entrada
    const cantidadRef = useRef(null);
    const precioCompraRef = useRef(null);
    const precioVentaRef = useRef(null);
    const descuentoRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [sumaTotal, setSumaTotal] = useState(0);

    const handleSumaTotalChange = (newSumaTotal) => {
        setSumaTotal(newSumaTotal);
    };

    const handleDelete = async (idDetalleCompra) => {
        try {
            await getProductos(); // Actualiza los productos
            setReload(!reload); // Refresca la tabla
        } catch (error) {
            console.error('Error al actualizar productos después de eliminar:', error);
        }
    };

    const getProductos = async () => {
        const request = await fetch(`${Global.url}producto/listar`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        });
        const data = await request.json();        
        setProductos(data.productos);
    }

    useEffect(() => {
        getProductos();
    }, []);

    useEffect(() => {
        if (form.productoID && cantidadRef.current) {
            cantidadRef.current.focus();
            cantidadRef.current.select();
        }
    }, [form.productoID]);

    const handleKeyDown = (e, nextRef) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (nextRef && nextRef.current) {
                nextRef.current.focus();
                nextRef.current.select();
            } else if (submitButtonRef.current) {
                submitButtonRef.current.click();
            }
        }
    };

    const handleProductChange = (e) => {
        const selectedProductId = e.target.value;
    
        // Actualiza el productoID en el formulario
        setForm({ ...form, productoID: selectedProductId });
    
        // Busca el producto en la lista de productos
        const selectedProduct = productos.find((producto) => producto.id === parseInt(selectedProductId, 10));
        
        if (selectedProduct) {
            // Actualiza los valores del formulario con los datos del producto
            setForm({
                ...form,
                productoID: selectedProductId,
                precio_compra: selectedProduct.precio_compra,
                precio_venta: selectedProduct.precio_venta,
            });
        }
    }; 

    const handleSubmit = async (e) => {
        e.preventDefault();

        const subtotal = form.cantidad * form.precio_compra;
        const total = subtotal - form.descuento;
        
        const url = editMode ? `${Global.url}detalle_compra/actualizar/${editId}` : `${Global.url}detalle_compra/registrar`;
        const method = editMode ? 'PUT' : 'POST';

        // Convertir los valores del formulario a números antes de enviarlos
        const parsedData = {
            id_producto: Number(form.productoID),
            precio_compra: Number(form.precio_compra),
            precio_venta: Number(form.precio_venta),
            cantidad: Number(form.cantidad),
            subtotal: Number(form.cantidad) * Number(form.precio_venta), // Por si acaso
            descuento: Number(form.descuento),
            total: (Number(form.cantidad) * Number(form.precio_venta)) - Number(form.descuento),
            id_compra: Number(id) // Si `id` viene de parámetros o algún otro lugar
        };

        const request = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            body: JSON.stringify(parsedData)
        });
        const data = await request.json();
        if (data.status === 'error') {
            Swal.fire({
                title: 'Faltan datos por enviar',
                text: 'Rellena los campos del formulario',
                icon: 'warning',
                confirmButtonColor: '#3085D6',
                confirmButtonText: `Ok`
            });
        } else {
            Swal.fire({
                title: editMode ? 'Actualizado' : 'Registrado',
                text: editMode ? 'Se ha actualizado correctamente' : 'Se ha registrado correctamente',
                icon: 'success',
                confirmButtonColor: '#3085D6',
                confirmButtonText: `Ok`
            });
        }
        setDetalleCompras([...detallesCompras, data]);

        // Actualiza la lista de productos
        await getProductos();
        resetForm();
        setEditMode(false);
        setEditId(null);
        setReload(!reload);
    };

    const handleEdit = (data) => {
        setForm({
            productoID: data.id_producto,
            cantidad: data.cantidad,
            precio_compra: data.precio_compra,
            precio_venta: data.precio_venta,
            descuento: data.descuento
        });
        setEditMode(true);
        setEditId(data.id);
    };

    const columns = [
        {
            header: 'Producto',
            accessor: 'producto'
        },
        {
            header: 'Precio por Unidad',
            accessor: 'precio_compra'
        },
        {
            header: 'Cantidad',
            accessor: 'cantidad'
        },
        {
            header: 'Precio Venta',
            accessor: 'precio_venta'
        },
        {
            header: 'Descuento',
            accessor: 'descuento'
        },
        {
            header: 'Total',
            accessor: 'total'
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className='d-flex flex-wrap align-items-left mb-3'>
                        <CardTitle tag="h4" className="mr-3" style={{ lineHeight: '1.5' }}>Agregar items</CardTitle>
                        <Link to="/app/producto" className='mx-2 mb-2 col-12 col-sm-auto'>
                            <ButtonWithRef className="btn col-12" outline color="secondary">
                                <i className="bi bi-plus-circle"></i> Producto
                            </ButtonWithRef>
                        </Link>
                        <Link to="/app/marca" className='mx-2 mb-2 col-12 col-sm-auto'>
                            <ButtonWithRef className="btn col-12" outline color="secondary">
                                <i className="bi bi-plus-circle"></i> Marca
                            </ButtonWithRef>
                        </Link>
                        <Link to="/app/categoria" className='mx-2 mb-2 col-12 col-sm-auto'>
                            <ButtonWithRef className="btn col-12" outline color="secondary">
                                <i className="bi bi-plus-circle"></i> Categoria
                            </ButtonWithRef>
                        </Link>
                    </div>
                    <div className='container' style={{ maxWidth: '100%' }}>
                        <form className="row" onSubmit={handleSubmit}>
                            <div className='col-md-3 mb-3'>
                                <label htmlFor='productoID'>Producto</label>
                                <select
                                    className="form-control"
                                    id='productoID'
                                    name='productoID'
                                    value={form.productoID}
                                    onChange={handleProductChange}
                                    onKeyDown={(e) => handleKeyDown(e, cantidadRef)}
                                >
                                    <option>Seleccione un producto</option>
                                    {productos.map((producto) => (
                                        <option key={producto.id} value={producto.id}>
                                            {producto.producto} {'(' + producto.stock + ')'}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-md-2 mb-3'>
                                <label htmlFor='cantidad'>Cantidad</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id='cantidad'
                                    name='cantidad'
                                    value={form.cantidad}
                                    onChange={changed}
                                    ref={cantidadRef}
                                    onKeyDown={(e) => handleKeyDown(e, precioCompraRef)}
                                />
                            </div>
                            <div className='col-md-2 mb-3'>
                                <label htmlFor='precio_compra'>Precio Compra</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id='precio_compra'
                                    name='precio_compra'
                                    value={form.precio_compra}
                                    onChange={changed}
                                    ref={precioCompraRef}
                                    onKeyDown={(e) => handleKeyDown(e, precioVentaRef)}
                                />
                            </div>
                            <div className='col-md-2 mb-3'>
                                <label htmlFor='precio_venta'>Precio Venta</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id='precio_venta'
                                    name='precio_venta'
                                    value={form.precio_venta}
                                    onChange={changed}
                                    ref={precioVentaRef}
                                    onKeyDown={(e) => handleKeyDown(e, descuentoRef)}
                                />
                            </div>
                            <div className='col-md-2 mb-3'>
                                <label htmlFor='descuento'>Descuento</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id='descuento'
                                    name='descuento'
                                    value={form.descuento}
                                    onChange={changed}
                                    ref={descuentoRef}
                                    onKeyDown={(e) => handleKeyDown(e, null)}
                                />
                            </div>
                            <div className='col-md-1 mb-3 mt-4'>
                                <ButtonWithRef className="btn col-12" color="primary" type="submit" ref={submitButtonRef}>
                                    <i className="bi bi-plus-circle col-sm-12"></i>
                                </ButtonWithRef>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <GenericTable
                        entity="detalle_compra"
                        typeEntity="producto"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        exportTitle="Listado de Nueva Compra"
                        reloadTrigger={reload}
                        showSearch={true}
                        showPagination={true}
                        onSumaTotalChange={handleSumaTotalChange}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center pt-2">
                        <div>
                            <ReactCountryFlag countryCode="PY" svg style={{ width: '2em', height: '2em' }} className="me-2 mb-3" />
                            <CardTitle tag="h5" className='d-inline' style={{ fontSize: '2em' }}>GS: {sumaTotal}</CardTitle>
                        </div>
                        <div>
                            <ReactCountryFlag countryCode="US" svg style={{ width: '2em', height: '2em' }} className="me-2 mb-3" />
                            <CardTitle tag="h5" className='d-inline' style={{ fontSize: '2em' }}>USD: {sumaTotal}</CardTitle>
                        </div>
                        <div>
                            <ReactCountryFlag countryCode="BR" svg style={{ width: '2em', height: '2em' }} className="me-2 mb-3" />
                            <CardTitle tag="h5" className='d-inline' style={{ fontSize: '2em' }}>RS: {sumaTotal}</CardTitle>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    );
}

export default NuevaCompra;

import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useForm from '../../hooks/useForm';
import useFetch from '../../hooks/useFetch';

const CrudIngreso = ({ isOpen, toggle, onSubmit, ingresoId, onUpdateIngreso }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedInUserId = user ? user.id : '';
    const { form, changed, resetForm, setForm } = useForm({
        id_cliente: '',
        id_usuario: '',
        fecha: '',
        id_comprobante: '',
        nro_comprobante: '',
        monto: '',
        id_metodo: '',
        concepto: ''
    });

    const { data: clientes, fetchAllData: fetchAllClientes } = useFetch('client');
    const { data: usuarios, fetchAllData: fetchAllUsuarios } = useFetch('user');
    const { data: metodos, fetchAllData: fetchAllMetodos } = useFetch('metodo');
    const { data: comprobantes, fetchAllData: fetchAllComprobantes } = useFetch('comprobante');

    useEffect(() => {
        const getIngreso = async () => {
            if (ingresoId) {
                const request = await fetch(`${Global.url}ingreso/obtener/${ingresoId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    }
                });
                const data = await request.json();
                const { ingreso } = data;
                
                setForm({
                    id_cliente: ingreso.id_cliente,
                    id_usuario: ingreso.id_usuario,
                    fecha: ingreso.fecha.split('T')[0],
                    id_comprobante: ingreso.id_comprobante,
                    nro_comprobante: ingreso.nro_comprobante,
                    monto: ingreso.monto,
                    id_metodo: ingreso.id_metodo,
                    concepto: ingreso.concepto
                });
            } else {
                const currentDate = new Date().toISOString().split('T')[0];
                setForm({
                    id_cliente: '',
                    id_usuario: loggedInUserId,
                    fecha: currentDate,
                    id_comprobante: '',
                    nro_comprobante: '',
                    monto: '',
                    id_metodo: '',
                    concepto: ''
                });
            }
        };

        if (isOpen) {
            getIngreso();
            fetchAllClientes();
            fetchAllUsuarios();
            fetchAllMetodos();
            fetchAllComprobantes();
        }

    }, [ingresoId, isOpen, setForm]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedIngreso = { ...form };
        const url = ingresoId ? `${Global.url}ingreso/actualizar/${ingresoId}` : `${Global.url}ingreso/registrar`;
        const method = ingresoId ? 'PUT' : 'POST';

        try {

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify(updatedIngreso)
            });

            const data = await response.json();
            
            if (data.status === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: `Ingreso ${ingresoId ? 'actualizado' : 'creado'} correctamente`,
                });

                onUpdateIngreso(); // Llama a la función para actualizar el ingreso en Ingreso.js
                resetForm();
                onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
                toggle(); // Cierra el modal
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message,
                });
                console.error(`Error ${ingresoId ? 'updating' : 'creating'} ingreso:`, data.message);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Ocurrió un error al ${ingresoId ? 'actualizar' : 'crear'} el ingreso`,
            });
            console.error(`Error ${ingresoId ? 'updating' : 'creating'} ingreso:`, error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className='modal-lg'>
            <ModalHeader toggle={toggle}>{ingresoId ? 'Editar' : 'Agregar'} Ingreso</ModalHeader>
            <ModalBody>
                <form id="editIngresoForm" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="id_cliente">Cliente</label>
                            <select className="form-control" id="id_cliente" name="id_cliente" value={form.id_cliente || ''} onChange={changed}>
                                <option value="">Seleccione un cliente</option>
                                {clientes?.map((cliente) => (
                                    <option key={cliente.id} value={cliente.id}>
                                        {cliente.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="id_usuario">Usuario</label>
                            <select className="form-control" id="id_usuario" name="id_usuario" value={form.id_usuario} onChange={changed}>
                                <option value="">Seleccione un usuario</option>
                                {usuarios.map((usuario) => (
                                    <option key={usuario.id} value={usuario.id}>
                                        {usuario.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-12'>
                            <label htmlFor='concepto'>Concepto</label>
                            <textarea
                                id="concepto"
                                value={form.concepto}
                                name='concepto'
                                onChange={changed}
                                rows="4"
                                placeholder="Escribe tu concepto aquí..."
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="id_metodo">Metodos de Pago</label>
                            <select className="form-control" id="id_metodo" name="id_metodo" value={form.id_metodo} onChange={changed}>
                                <option value="">Seleccione un metodo</option>
                                {metodos.map((metodo) => (
                                    <option key={metodo.id} value={metodo.id}>
                                        {metodo.descripcion}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="id_comprobante">Comprobantes</label>
                            <select className="form-control" id="id_comprobante" name="id_comprobante" value={form.id_comprobante} onChange={changed}>
                                <option value="">Seleccione un comprobante</option>
                                {comprobantes.map((comprobante) => (
                                    <option key={comprobante.id} value={comprobante.id}>
                                        {comprobante.descripcion}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor='nro_comprobante'>Nº de Comprobante</label>
                            <input type="number" className="form-control" id="nro_comprobante" name="nro_comprobante" placeholder='Ingrese el numero'
                                value={form.nro_comprobante} onChange={changed} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor='nro_comprobante'>Monto</label>
                            <input type="number" className="form-control" id="monto" name="monto" placeholder='Ingrese el monto'
                                value={form.monto} onChange={changed} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor='fecha'>Fecha de Registro</label>
                            <input type="date" className="form-control" id="fecha" name="fecha" value={form.fecha} onChange={changed} />
                        </div>
                    </div>
                    <div className="row">
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cerrar</Button>
                <Button color="primary" type="submit" form="editIngresoForm">Guardar cambios</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CrudIngreso;

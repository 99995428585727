import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';
import useAuth from '../../hooks/useAuth';
import useForm from '../../hooks/useForm';
import { getCroppedImg } from '../../helpers/CropImage';

const CrudUser = ({ isOpen, toggle, onSubmit, userId, onUpdateUser }) => {
  const { auth, setAuth } = useAuth();
  const { form, files, changed, resetForm, setForm } = useForm({
    nombre: '',
    user: '',
    nivel: 1,
    password: '',
    foto_perfil: null
  });

  const [imageSrc, setImageSrc] = useState(null); // Imagen cargada
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Posición del recorte
  const [zoom, setZoom] = useState(1); // Nivel de zoom
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null); // Área seleccionada
  const [croppedImage, setCroppedImage] = useState(null); // Imagen recortada

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);

      // Convierte el Blob a un archivo
      const croppedFile = new File([croppedBlob], "avatar-cropped.jpg", {
        type: "image/jpeg", // Asegúrate de usar un tipo MIME válido
      });

      setCroppedImage(croppedFile); // Guarda el archivo para enviarlo al servidor
    } catch (e) {
      console.error(e);
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (croppedImage) {
      const formData = new FormData();
      formData.append("file0", croppedImage);

      try {
        const response = await fetch(`${Global.url}user/upload`, {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          Swal.fire("Éxito", "Imagen subida correctamente", "success");
        } else {
          Swal.fire("Error", "Error al subir la imagen", "error");
        }
      } catch (error) {
        console.error("Error al subir la imagen:", error);
      }
    }
  };


  useEffect(() => {
    const getUser = async () => {
      if (userId) {
        const request = await fetch(`${Global.url}user/obtener/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
          }
        });
        const data = await request.json();
        const { user } = data;
        setForm({
          nombre: user.nombre,
          user: user.user,
          nivel: user.nivel,
          password: '',
          foto_perfil: user.foto_perfil
        });
      } else {
        resetForm();
      }
    };

    if (isOpen) {
      getUser();
    }
  }, [userId, isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedUser = { ...form };
    const url = userId ? `${Global.url}user/actualizar/${userId}` : `${Global.url}user/registrar`;
    const method = userId ? 'PUT' : 'POST';

    try {

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(updatedUser)
      });

      const data = await response.json();

      if (data.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: `Usuario ${userId ? 'actualizado' : 'creado'} correctamente`,
        });
        // if (respuesta) {
        //   data.user.foto_perfil = respuesta.image;
        // }
        let userSession = JSON.parse(localStorage.getItem('user'));

        if (userId && userId == userSession.id) {
          setAuth(data.user);
        }
        onUpdateUser(); // Llama a la función para actualizar el usuario en User.js
        resetForm();
        onSubmit(); // Aquí puedes realizar cualquier acción adicional al enviar el formulario
        toggle(); // Cierra el modal
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message,
        });
        console.error(`Error ${userId ? 'updating' : 'creating'} user:`, data.message);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Ocurrió un error al ${userId ? 'actualizar' : 'crear'} el usuario`,
      });
      console.error(`Error ${userId ? 'updating' : 'creating'} user:`, error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{userId ? 'Editar' : 'Agregar'} Usuario</ModalHeader>
      <ModalBody>
        <form id="editUserForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              placeholder="Ingrese el nombre"
              value={form.nombre}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label htmlFor="user">Usuario</label>
            <input
              type="text"
              className="form-control"
              id="user"
              name="user"
              placeholder="Ingrese el usuario"
              value={form.user}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{userId ? 'Nueva contraseña' : 'Contraseña'}</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder={`Ingrese ${userId ? 'nueva ' : ''}contraseña`}
              value={form.password}
              onChange={changed}
            />
          </div>
          <div className="form-group">
            <label>Rol</label>
            <select
              className="form-control"
              name="nivel"
              value={form.nivel}
              onChange={changed}
            >
              <option value="1">Administrador</option>
              <option value="2">Usuario</option>
            </select>
          </div>
          {/* {auth.id == userId && (
            <div className="form-group">
              <label htmlFor="foto_perfil">Foto de Perfil</label>
              <input
                type="file"
                className="form-control"
                id="foto_perfil"
                name="foto_perfil"
                onChange={changed}
              />
            </div>
          )} */}
          <div className="form-group">
            <label htmlFor="foto_perfil">Foto de Perfil</label>
            {auth.id == userId ? (
              <>
                <input
                  type="file"
                  className="form-control"
                  id="foto_perfil"
                  name="foto_perfil"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                {imageSrc && (
                  <div className="crud-user">
                    {!croppedImage ? (
                      <>
                        <div className="crop-container">
                          <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                          />
                        </div>
                        <div className="controls">
                          <input
                            type="range"
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e) => setZoom(e.target.value)}
                          />
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                          <Button color="primary" onClick={showCroppedImage}>
                            Recortar Imagen
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                          <img
                            src={URL.createObjectURL(croppedImage)}
                            alt="Vista previa"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '200px',
                              borderRadius: '10px',
                              border: '1px solid #ccc',
                              marginBottom: '10px',
                            }}
                          />
                          <p style={{ color: '#28a745', fontWeight: 'bold' }}>
                            Vista previa lista para subir
                          </p>
                          <Button color="success" onClick={handleUpload}>
                            Subir Imagen
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <p style={{ color: 'gray' }}>Solo puedes modificar tu propia foto de perfil</p>
            )}
          </div>

        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cerrar</Button>
        <Button color="primary" type="submit" form="editUserForm">Guardar cambios</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CrudUser;

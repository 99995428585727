import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import CrudComprobante from './CrudComprobante';
import GenericTable from '../datatables/GenericTable';
import useFetch from '../../hooks/useFetch';

const Comprobante = () => {
    const { data: comprobante, loading, error, fetchData, fetchSearchData, setData: setComprobante } = useFetch('comprobante');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedComprobanteId, setSelectedComprobanteId] = useState(null);
    const [reload, setReload] = useState(false);

    const toggleModal = (comprobanteId = null) => {
        setSelectedComprobanteId(comprobanteId);
        setModalOpen(!modalOpen);
    };

    const handleReload = () => {
        setReload(prev => !prev);
    };

    const handleSubmit = () => {
        toggleModal();
    };

    const columns = [
        {
            header: 'Descripcion',
            accessor: 'descripcion'
        }
    ];

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h4">Listado de Comprobantes</CardTitle>
                        <Button color="primary" onClick={() => toggleModal()}>
                            <i className="bi bi-plus-circle"></i> Comprobante
                        </Button>
                    </div>

                    <GenericTable
                        entity="comprobante"
                        typeEntity="comprobante"
                        columns={columns}
                        fetchData={fetchData}
                        fetchSearchData={fetchSearchData}
                        onEdit={(comprobante) => toggleModal(comprobante.id)}
                        onDelete={() => { }}
                        exportTitle="Listado de Comprobantes"
                        reloadTrigger={reload}
                    />
                </CardBody>
            </Card>
            <CrudComprobante 
                isOpen={modalOpen} 
                toggle={toggleModal} 
                onSubmit={handleSubmit} 
                comprobanteId={selectedComprobanteId} 
                onUpdateComprobante={handleReload}
            />
        </div>
    )
}

export default Comprobante;

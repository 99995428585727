import { Button, Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';

const navigation = [
  {
    title: "Usuarios",
    href: "/app/users",
    icon: "bi bi-person",
  },
  {
    title: "Clientes",
    href: "/app/clients",
    icon: "bi bi-people",
  },
  {
    title: "Productos",
    icon: "bi bi-box-seam",
    subItems: [
      {
        title: "Productos",
        href: "/app/producto",
      },
      {
        title: "Categorías",
        href: "/app/categoria",
      },
      {
        title: "Marcas",
        href: "/app/marca",
      },
    ],
  },
  {
    title: "Compras",
    href: "/app/compra",
    icon: "bi bi-cart",
  },
  {
    title: "Ventas",
    href: "/app/venta",
    icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />,
  },
  {
    title: "Financiero",
    icon: "bi bi-currency-dollar",
    subItems: [
      {
        title: "Acreedores",
        href: "/app/acreedor",
      },
      {
        title: "Deudores",
        href: "/app/deudor",
      },
      {
        title: "Ingresos",
        href: "/app/ingreso",
      },
      {
        title: "Egresos",
        href: "/app/egreso",
      },
      {
        title: "Cambios",
        href: "/app/moneda",
      }
    ],
  },
  // {
  //   title: "Tablero",
  //   href: "/app/starter",
  //   icon: "bi bi-speedometer2",
  // },
  // {
  //   title: "Alerta",
  //   href: "/app/alerts",
  //   icon: "bi bi-bell",
  // },
  // {
  //   title: "Insignias",
  //   href: "/app/badges",
  //   icon: "bi bi-patch-check",
  // },
  // {
  //   title: "Botones",
  //   href: "/app/buttons",
  //   icon: "bi bi-hdd-stack",
  // },
  // {
  //   title: "Tarjetas",
  //   href: "/app/cards",
  //   icon: "bi bi-card-text",
  // },
  // {
  //   title: "Grilla",
  //   href: "/app/grid",
  //   icon: "bi bi-columns",
  // },
  // {
  //   title: "Tabla",
  //   href: "/app/table",
  //   icon: "bi bi-layout-split",
  // },
  // {
  //   title: "Formularios",
  //   href: "/app/forms",
  //   icon: "bi bi-textarea-resize",
  // },
  // {
  //   title: "Migas de pan",
  //   href: "/app/breadcrumbs",
  //   icon: "bi bi-link",
  // },
  // {
  //   title: "Acerca de",
  //   href: "/app/about",
  //   icon: "bi bi-people",
  // }
];

const Sidebar = () => {
  // const showMobilemenu = () => {
  //   document.getElementById("sidebarArea").classList.toggle("showSidebar");
  // };
  const location = useLocation();
  const [collapsed, setCollapsed] = useState({});

  const toggleCollapse = (index) => {
    setCollapsed((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      {/* <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div> */}
      <div className="p-2 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            navi.subItems ? (
              <div key={index}>
                <NavItem className="sidenav-bg">
                  <Button
                    color="link"
                    className="nav-link py-3 d-flex align-items-center w-100"
                    onClick={() => toggleCollapse(index)}
                  >
                    {/* Renderiza el ícono */}
                    {typeof navi.icon === "string" ? (
                      <i className={navi.icon}></i> // Maneja clases CSS
                    ) : (
                      navi.icon // Maneja componentes React
                    )}
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                    <i className={`ms-auto bi ${collapsed[index] ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                  </Button>
                </NavItem>
                <ul className={`collapse list-unstyled ${collapsed[index] ? 'show' : ''}`}>
                  {navi.subItems.map((subItem, subIndex) => (
                    <li key={subIndex} className="nav-item">
                      <Link
                        to={subItem.href}
                        className={
                          location.pathname === subItem.href
                            ? "active nav-link py-3"
                            : "nav-link py-3"
                        }
                      >
                        {subItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <NavItem key={index} className="sidenav-bg">
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "active nav-link py-3"
                      : "nav-link py-3"
                  }
                >
                  {/* Renderiza el ícono */}
                  {typeof navi.icon === "string" ? (
                    <i className={navi.icon}></i> // Maneja clases CSS
                  ) : (
                    navi.icon // Maneja componentes React
                  )}
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                </Link>
              </NavItem>
            )
          ))}
        </Nav>
      </div>
    </>
  );
};

export default Sidebar;

import React from 'react';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { Global } from '../../helpers/Global';

const DeleteButton = ({ entity, typeEntity, entityId, onDeleteSuccess }) => {
  const handleDelete = async () => {
    try {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: 'No podrás revertir esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: `Sí, eliminar ${typeEntity}`
      });

      if (result.isConfirmed) {
        const request = await fetch(`${Global.url}${entity}/eliminar/${entityId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
          }
        });

        const data = await request.json();

        if (data.status === 'success') {
          Swal.fire(
            'Eliminado!',
            `El ${typeEntity} ha sido eliminado.`,
            'success'
          );

          onDeleteSuccess(entityId);
        } else {
          Swal.fire(
            'Error',
            `No se pudo eliminar el ${typeEntity}. Por favor, inténtalo de nuevo más tarde.`,
            'error'
          );
        }
      }
    } catch (error) {
      console.error(`Error al eliminar ${typeEntity}:`, error);
      Swal.fire(
        'Error',
        'Ocurrió un error al procesar tu solicitud. Por favor, inténtalo de nuevo más tarde.',
        'error'
      );
    }
  };

  return (
    <Button color="danger" onClick={handleDelete}>
      <i className="bi bi-trash-fill"></i> Eliminar
    </Button>
  );
};

export default DeleteButton;
